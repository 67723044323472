import { IFeatureFlags } from '~/dataStore/App.interface'

export enum MODES {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production'
}

export type IApplication = {
  id: string
  name: string
  image: string
  mode: MODES
  pemExpiry: string | null
  p8Certificate: boolean
  featureFlags: IFeatureFlags
}

export const ERRORS_DICT = {
  name: {
    'name is invalid': 'Wrong app Name',
    "can't be blank": 'App Name is missing'
  },
  password: {
    'password is invalid': 'Wrong Admin Password'
  },
  mode: {
    'Missing parameter mode': 'App mode is missing',
    'Must be one of: <code>production</code>, <code>development</code>.':
      'App mode is missing'
  },
  appleCertificate: {
    "can't be blank": 'Apple Certificate is missing'
  },
  fcmJsonSecret: {
    "can't be blank": 'FCM JSON Secret is missing',
    'Fcm json secret file is empty': 'FCM JSON secret file is empty',
    'Invalid file format (.json file only)':
      'Invalid file format (.json file only)'
  }
}

export type DeleteAppFormValues = {
  name: string
  password: string
  general: string
}

export interface IAppsDTO {
  data: IApplication[]
  metadata: {
    dataCount: number
    page: number
    perPage: number
    totalPages: number
  }
}

export interface IAppsListQueryParams {
  page?: number
  perPage?: number
  sortField?: string
  order?: 'asc' | 'desc'
}

export interface IMetaData {
  dataCount: number
  page: number
  perPage: number
  totalPages: number
}
