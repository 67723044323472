import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Label,
  Row
} from 'reactstrap'
import { updateAPNSSettings, updateAPNSSettingsP8 } from '~/api/appSettings'
import Input from '~/components/forms/Input'
import { useStore, withStore } from '~/dataStore'
import { NotificationType, showNotification } from '~/utils/Notification'
import { getFormServerSideErrors, showGeneralError } from '~/utils/validations'
import { ERRORS_DICT, FormValues } from './ApplePushSection.interface'
import sectionLogo from './section-logo.svg'

import ToggleRadioSwitch from '~/components/forms/ToggleRadioSwitch'
import styles from './ApplePushSection.scss'

const ApplePushSection = (): React.ReactElement => {
  const { appId } = useParams<{ appId: string }>()
  const {
    app: {
      appDetails,
      currentAdmin: { isDemoAdmin }
    }
  } = useStore()

  const [certificateType, setCertificateType] = useState<'pem' | 'p8'>(
    appDetails?.apns.p8 ? 'p8' : 'pem'
  )

  useEffect(() => {
    setCertificateType(appDetails?.apns.p8 ? 'p8' : 'pem')
  }, [appDetails?.apns.p8])

  const initialValues: FormValues = {
    appleCertificate: appDetails?.apns.certificate.identifier,
    appleBundleId: appDetails?.apns.bundleId,
    applePassword: appDetails?.apns.password,
    appleKeyId: appDetails?.apns.keyId,
    appleTeamId: appDetails?.apns.teamId
  }

  const {
    register,
    handleSubmit,
    setError,
    watch,
    setValue,
    formState: { errors, isSubmitting }
  } = useForm<FormValues>({
    values: initialValues
  })

  const onSubmit = async (data: FormValues) => {
    const formData = new FormData()
    if (
      data.appleCertificate instanceof FileList &&
      data.appleCertificate.length > 0
    ) {
      formData.append(
        'setting_attributes[apple_certificate]',
        data.appleCertificate[0]
      )
    }

    formData.append(
      'setting_attributes[apple_bundle_id]',
      data.appleBundleId || ''
    )

    try {
      if (certificateType === 'pem') {
        formData.append(
          'setting_attributes[apple_password]',
          data.applePassword || ''
        )
        await updateAPNSSettings(appId, formData)
      } else {
        formData.append(
          'setting_attributes[apple_key_id]',
          data.appleKeyId || ''
        )
        formData.append(
          'setting_attributes[apple_team_id]',
          data.appleTeamId || ''
        )
        await updateAPNSSettingsP8(appId, formData)
      }

      showNotification('Settings successfully saved', NotificationType.SUCCESS)
    } catch (error: Error & { body: any }) {
      if (error.body?.errors) {
        getFormServerSideErrors(error.body.errors, ERRORS_DICT).forEach((er) =>
          setError(er.name, { type: 'server', message: er.message })
        )
      } else {
        showGeneralError()
      }
    }
  }

  const getCertificateName = () => {
    const certificate = watch('appleCertificate')

    if (certificate instanceof FileList) {
      if (certificate.length === 0) {
        return 'No file chosen'
      }
      return certificate?.[0]?.name
    }
    return certificate || ''
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h3" className="mb-0 mt-2">
          Apple Push Notification Service (APNs)
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Col xs={8}>
          <ToggleRadioSwitch
            className="mb-3"
            selected={certificateType}
            options={[
              { name: 'appleCertificateType', label: 'PEM', value: 'pem' },
              { name: 'appleCertificateType', label: 'P8', value: 'p8' }
            ]}
            onChange={(e) => {
              setCertificateType(e.target.value as 'pem' | 'p8')
              setValue('appleCertificate', null)
            }}
          />
        </Col>
        <form onSubmit={handleSubmit(onSubmit)} className="py-3">
          <Row>
            <Col xs={8}>
              <div className="d-flex">
                <div className="mb-3">
                  <Label className="fw-medium d-block position-absolute form-label">
                    Upload Certificate
                  </Label>
                  <Label
                    htmlFor="appleCertificateUpload"
                    className={cn(
                      styles.uploadButton,
                      'btn btn-primary d-flex',
                      {
                        [styles.uploadButtonError]:
                          errors.appleCertificate?.message
                      }
                    )}>
                    Choose file
                  </Label>
                </div>
                <div className="w-100">
                  <Input
                    id="appleCertificateUpload"
                    label=""
                    accept={certificateType === 'pem' ? '.pem' : '.p8'}
                    name="appleCertificate"
                    register={register}
                    className={cn(styles.uploadInputWrapper)}
                    type="file"
                  />
                  <Input
                    label=" "
                    readOnly
                    value={getCertificateName()}
                    className={cn(styles.fileNameInputWrapper, {
                      'form-group--error': errors.appleCertificate?.message,
                      [styles.fileNameInputError]:
                        errors.appleCertificate?.message
                    })}
                    type="text"
                    errorTooltip={errors?.appleCertificate?.message}
                    id="appleCertificate"
                    tooltip="Upload your PEM file here Click if you need help"
                  />
                </div>
              </div>

              <Input
                placeholder="Your Apple Bundle ID"
                tooltip="A bundle ID or bundle identifier uniquely identifies an application in Apple's ecosystem."
                register={register as any}
                label="Apple Bundle ID"
                name="appleBundleId"
                className="mb-3"
              />

              {certificateType === 'pem' ? (
                <Input
                  label="Apple Certificate Password"
                  name="applePassword"
                  className={cn('mb-3', {
                    'form-group--error': errors.applePassword
                  })}
                  register={register}
                  placeholder="Your Apple Certificate Password"
                  tooltip="If you set a password for your cert, enter it here"
                />
              ) : (
                <>
                  <Input
                    label="Apple Key ID"
                    name="appleKeyId"
                    className={cn('mb-3', {
                      'form-group--error': errors.appleKeyId
                    })}
                    register={register}
                    placeholder="Your Apple Key ID"
                    tooltip="Enter your 10-character Apple Key ID"
                  />
                  <Input
                    label="Apple Team ID"
                    name="appleTeamId"
                    className={cn('mb-3', {
                      'form-group--error': errors.appleTeamId
                    })}
                    register={register}
                    placeholder="Your Apple Team ID"
                    tooltip="Enter your 10-character Apple Team ID"
                  />
                </>
              )}

              <Button
                color="primary"
                disabled={isSubmitting || isDemoAdmin}
                type="submit"
                className="mt-4 d-block">
                Save Changes
                {isSubmitting && (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    size="xs"
                    className="ms-2"
                  />
                )}
              </Button>
            </Col>
            <Col
              xs={4}
              className="d-flex align-items-center justify-content-center">
              <img
                src={sectionLogo}
                width={148}
                alt="Apple Push Notification Service (APNs)"
              />
            </Col>
          </Row>
        </form>
      </CardBody>
    </Card>
  )
}

export default withStore(ApplePushSection)
