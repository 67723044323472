import { IApplication } from '../AppManager.interface'
import styles from '../AppManager.scss'
import DeleteAppButton from './DeleteAppButton'
import ModeColumn from './ModeColumn'
import NameColumn from './NameColumn'
import PemExpiryColumn from './PemExpiryColumn'

const columns = [
  {
    fieldName: 'name',
    displayName: 'Name',
    // eslint-disable-next-line react/display-name
    render: ({ row }: { row: IApplication }) => <NameColumn {...row} />,
    sortable: false,
    classes: styles.nameColumn
  },
  {
    fieldName: 'mode',
    displayName: 'Mode',
    classes: styles.modeColumn,
    render: ({ row: { mode } }: { row: IApplication }) => (
      <ModeColumn mode={mode} />
    ),
    sortable: false
  },
  {
    fieldName: 'pemExpiry',
    displayName: 'PEM Certificate (iOS)',
    render: ({ row: { pemCertificate } }: { row: IApplication }) => (
      <PemExpiryColumn pemData={pemCertificate} />
    ),
    sortable: false
  },
  {
    fieldName: 'P8',
    displayName: 'P8 Certificate (iOS)',
    render: ({ row: { p8Certificate } }: { row: IApplication }) => (
      <span>{p8Certificate ? 'Yes' : 'No'}</span>
    ),
    sortable: false
  },
  {
    displayName: '',
    render: ({ row }: { row: IApplication }) => (
      <div className="row-actions">
        <DeleteAppButton application={row} />
      </div>
    ),
    sortable: false
  }
]

export default columns
